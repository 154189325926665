import React, {useEffect, useState} from "react";
import api from "../../../services/api";

export default function PresenceReport({ filtro }:any) {
    const [athletes, setAthletes] = useState<any>([])
    const [loading, setLoading] = useState<any>(true)

    async function getAthletes() {
        await api.post(`/api/app/presence/report`, {filtro}).then((response) => {
            setAthletes(response.data)
        }).then(() => {
            setLoading(false)
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            await getAthletes()
        };

        fetchData()
    }, [])

    return (
        <>
            {!loading &&
                <div className={'printTable'}>
                    <p style={{textAlign: 'center'}}>&nbsp;</p>
                    <p style={{textAlign: 'center'}}><span><strong>Relatório de Presenças</strong></span>
                    </p>

                    <table style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100%'
                    }} className={'table'}>
                        <tbody>
                        <tr>
                            <td style={{textAlign: 'center'}} width="230"> Data</td>
                            <td  width="600">Nome </td>
                            <td width="200">CR</td>
                            <td width="200" >CPF</td>
                        </tr>

                        {athletes.map((athlete: any, index: number) =>
                            <tr key={`${index}`}>
                                <td style={{textAlign: 'center'}}>{athlete?.capture_timestamp ? new Date(athlete?.capture_timestamp).toLocaleString('pt-BR') : ''}</td>
                                <td>{athlete?.athlete?.full_name}</td>
                                <td>{athlete?.athlete.cr_number}</td>
                                <td>{athlete?.athlete.cpf}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}
