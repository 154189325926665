import React, {useContext, useEffect, useState} from "react";
import Select from "react-select";
import api from "../../services/api";
import Swal from "sweetalert2";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {AuthContext} from "../../context/AuthContext";
import {MainContext} from "../../context/MainContext";
import {SelectTheme} from "../../components/selectTheme";
import Async from "react-select/async";
import ICollections from "../../interfaces/IWeaponCollections";
import IWeaponGuides from "../../interfaces/IWeaponGuides";

export default function FormWeapons() {
    let { id} = useParams()
    const navigate = useNavigate()
    const { authUser , isAthlete} = useContext(AuthContext)
    const { setPageTitle, setLoading } = useContext(MainContext)

    const [arrayWeaponCategory, setArrayWeaponCategory] = useState([] as any)
    const [arrayCaliber, setArrayCaliber] = useState([] as any)
    const [arrayWeaponOperation, setArrayWeaponOperation] = useState([] as any)
    const [arrayWeaponCollection, setArrayWeaponCollection] = useState([] as any)
    const [arrayGuideType, setArrayGuideType] = useState([] as any)

    const [active, setActive] = useState(true);
    const [athlete, setAthlete] = useState(0);
    const [restricted, setRestricted] = useState(0);
    const [weaponSize, setWeaponSize] = useState('');
    const [weaponBarrel, setWeaponBarrel] = useState('');
    const [weaponGroup, setWeaponGroup] = useState('');
    const [description, setDescription] = useState('');
    const [sigmaNumber, setSigmaNumber] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [caliber, setCaliber] = useState('' as any)
    const [weaponCategory, setWeaponCategory] = useState('' as any)
    const [weaponBrand, setWeaponBrand] = useState({
        value: null,
        label: 'Pesquisar marca',
    } as any)
    const [weaponCollection, setWeaponCollection] = useState([] as any)
    const [weaponOperation, setWeaponOperation] = useState('' as any)
    const [guideType, setGuideType] = useState('' as any)

    const [crafExpedition, setcrafExpedition] = useState(null as any)
    const [crafValidity, setCrafValidity] = useState(null as any)

    const [weaponGuides, setWeaponGuides] = useState<IWeaponGuides[]>([]);

    const [observation, setObservation] = useState('');

    const dispatchWeaponObject:any = {
        "active": active,
        "athlete_id": athlete,
        "description": description,
        "restricted": restricted,
        "weapon_size": weaponSize,
        "weapon_barrel": weaponBarrel,
        "weapon_group": weaponGroup,
        "serial_number": serialNumber,
        "sigma_number": sigmaNumber,
        "caliber_id": caliber,
        "weapon_category_id": weaponCategory,
        "weapon_brand_id": weaponBrand?.value,
        "weapon_collections": weaponCollection.map((option: { value: number; }) => option.value),
        "weapon_operation_id": weaponOperation,

        "craf_expedition": crafExpedition,
        "craf_validity": crafValidity,

        "weapon_guides": weaponGuides
    }

    const validateWeaponRegister = () => {
        if (!description) {
            throw "Modelo deve ser informado";
        }

        if (typeof restricted === 'object' && Object.keys(restricted).length === 0) {
            throw "Tipo de uso deve ser informada";
        }

        if (!serialNumber) {
            throw "Número de série deve ser informado";
        }

        if (!sigmaNumber) {
            throw "Número do sigma deve ser informado";
        }

        if (!weaponCategory) {
            throw "Espécie deve ser informada";
        }

        if (!caliber) {
            throw "Calibre deve ser informado";
        }

        if (!weaponOperation) {
            throw "Funcionamento deve ser informado";
        }

        if (!weaponBrand.value) {
            throw "Marca deve ser informada";
        }

        if (weaponCollection.length === 0) {
            throw "Acervo deve ser informado";
        }

        if (!crafExpedition) {
            throw "Data de expedição do CRAF deve ser informada";
        } else if (!dayjs(crafExpedition).isValid()){
            throw `Data de expedição do CRAF esta inválida`;
        }

        if (!crafValidity) {
            throw "Data de validade do CRAF deve ser informada";
        } else if (!dayjs(crafValidity).isValid()){
            throw `Data de validade do CRAF esta inválida`;
        }

        if (weaponGuides.length > 0){
            weaponGuides.forEach((guide:IWeaponGuides, index:number) => {
                if (!guide?.guide_type_id){
                    throw `Tipo da guia ${index +1} deve ser informado`;
                }

                if (!guide?.guide_expedition){
                    throw `Data de expedição da guia ${index +1} deve ser informada`;
                } else if (!dayjs(guide?.guide_expedition).isValid()){
                    throw `Data de expedição da guia ${index +1} esta inválida`;
                }

                if (!guide?.guide_validity){
                    throw `Data de validade da guia ${index +1} deve ser informada`;
                } else if (!dayjs(guide?.guide_expedition).isValid()){
                    throw `Data de validade da guia ${index +1} esta inválida`;
                }
            })
        }
    }

    async function calibers(category = '') {
        const response = await api.get(`/api/calibers`)
        const result = await response
        return setArrayCaliber(
            await result.data.map((caliber:any) => ({
                value: caliber.id,
                label: caliber.description
            })),
        )
    }

    async function weaponCategories(value = '') {
        const response = await api.get(`/api/weaponCategories`)
        const result = await response
        return setArrayWeaponCategory(
            await result.data.map((category:any) => ({
                value: category.id,
                label: category.description
            })),
        )
    }

    async function weaponsBrands(value = '') {
        const response = await api.get(`/api/weaponBrands/?search=${value}`)
        const result = await response
        return await result.data.map((brand:any) => ({
                value: brand.id,
                label: brand.description
            }))
    }

    async function weaponCollections(value = '') {
        const response = await api.get(`/api/weaponCollections`)
        const result = await response
        return setArrayWeaponCollection(
            await result.data.map((collection:ICollections) => ({
                value: collection.id,
                label: collection.description
            })),
        )
    }

    async function weaponOperarions(value = '') {
        const response = await api.get(`/api/weaponOperations`)
        const result = await response
        return setArrayWeaponOperation(
            await result.data.map((operation:any) => ({
                value: operation.id,
                label: operation.description
            })),
        )
    }

    async function guideTypes(value = '') {
        const response = await api.get(`/api/guideTypes`)
        const result = await response
        return setArrayGuideType(
            await result.data.map((guide:any) => ({
                value: guide.id,
                label: guide.description
            })),
        )
    }

    const resgister = async (evento: React.FormEvent<HTMLFormElement>) => {
        setLoading(true)
        try {
            evento.preventDefault();

            validateWeaponRegister()

            if (id == undefined) {
                await api.post('/api/weapons', dispatchWeaponObject).then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                        timer: 5000
                    }).then((result) => {
                        return navigate(isAthlete ? '/weapons': '/habitualities/create');
                    });
                })
                    .catch(erro => {
                            Swal.fire({
                                title: 'Erro!',
                                text: erro.response.data.message,
                                icon: 'error',
                            })
                        }
                    );
            } else {
                await api.put(`/api/weapons/${id}`, dispatchWeaponObject).then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                    }).then((result) => {
                        return navigate(isAthlete ? '/weapons': '/habitualities/create');
                    });
                })
                    .catch(erro  => Swal.fire({
                            title: 'Erro!',
                            text: erro.response.data.message,
                            icon: 'error',
                        })
                    )
            }
        }catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setPageTitle("Cadastro de Arma")
        setLoading(true)

        if (isAthlete && (!authUser?.athlete?.cr_number || !authUser?.athlete?.cr_expedition_date || !authUser?.athlete?.cr_validity_date)){
            setLoading(false)
            Swal.fire({
                title: 'Atenção!',
                text: "Informações do CR devem ser informadas no seu perfil!",
                icon: 'warning',
            }).then(()=> {
                return navigate("/profile");
            })
        }

        const fetchData = async () => {
            try {
                await calibers()
                await weaponCategories()
                await weaponsBrands()
                await weaponCollections()
                await weaponOperarions()
                await guideTypes()

                if (id != undefined) {
                    await api.get(`/api/weapon/${id}`)
                        .then(resposta => {
                            const weapon = resposta.data

                            setActive(weapon?.active)
                            setAthlete(weapon?.athlete_id)
                            setDescription(weapon?.description)
                            setRestricted(weapon?.restricted)
                            setWeaponSize(weapon?.weapon_size)
                            setWeaponBarrel(weapon?.weapon_barrel)
                            setWeaponGroup(weapon?.weapon_group)
                            setSerialNumber(weapon?.serial_number)
                            setSigmaNumber(weapon?.sigma_number)
                            setCaliber(weapon?.caliber_id)
                            setWeaponBrand({
                                    value: weapon?.weapon_brand_id,
                                    label: weapon?.weapon_brand?.description,
                            })
                            setWeaponCategory(weapon?.weapon_category_id)

                            const weapon_collections = weapon?.weapon_collections.map((collection: ICollections) => ({
                                value: collection.id,
                                label: collection.description
                            }));
                            setWeaponCollection(weapon_collections)
                            setWeaponOperation(weapon?.weapon_operation_id)
                            setWeaponGuides(weapon?.weapon_guides)

                            setcrafExpedition(weapon?.craf_expedition)
                            setCrafValidity(weapon?.craf_validity != null ? dayjs(weapon?.craf_validity) : null)
                        })
                }else{
                    setAthlete(authUser.athlete.id)
                }
            }catch (e) {
                console.log(e)
            }finally {
                setLoading(false)
            }
        };

        fetchData()
    },  [id])

    const handleWeaponGuideChange = (index: number, field: string, value: any) => {
        const newGuide = [...weaponGuides];
        newGuide[index] = {
            ...newGuide[index],
            [field]: value,
        };
        setWeaponGuides(newGuide);
    };

    const addGuide = () => {
        setWeaponGuides([
            ...weaponGuides,
            {guide_expedition: null, guide_validity: null , observation: '' ,defeated:0},
        ]);
    };

    const removeGuide = (index: number) => {
        Swal.fire({
            title: 'Tem certeza que deseja excluir a guia?',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#198754",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                const guides = weaponGuides.filter((_, i) => i !== index);
                setWeaponGuides(guides);
            }
        });
    };

    return (
        <>
            <div className="col-lg-12">

                <form onSubmit={resgister} autoComplete='off'>
                    <div className="card">
                        <div className="card-body">
                            <div className="row g-3 mt-2">
                                <div className="col-md-12">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="active"
                                               onChange={evento => setActive(evento.target.checked)}
                                               checked={active}/>
                                        <label className="form-check-label" htmlFor="active">Ativo</label>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <Select
                                            defaultValue={{label: 'Permitida', value: 0}}
                                            options={[{label: 'Permitida', value: 0}, {label: 'Restrita', value: 1}]}
                                            value={[{label: 'Permitida', value: 0}, {label: 'Restrita', value: 1}
                                            ].find(option => option.value == restricted)}
                                            name='restricted'
                                            id='restricted'
                                            onChange={(data: any) => {
                                                setRestricted(data.value)
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-9">
                                        <Select
                                            defaultValue={{label: 'Grupo 1 - Arma Curta Uso Permitido', value: '1'}}
                                            options={[{label: 'Grupo 1 - Arma Curta Uso Permitido', value: '1'}, {label: 'Grupo 2 - Arma Longa Raiada Uso Permitido', value: '2'}, {label: 'Grupo 3 - Arma Longa Lisa Uso Permitido', value: '3'}, {label: 'Grupo 4 - Arma Curta Uso Restrito', value: '4'}, {label: 'Grupo 5 - Arma Longa Raiada Uso Restrito', value: '5'}, {label: 'Grupo 6 - Arma Longa Lisa Uso Restrito', value: '6'}]}
                                            value={[{label: 'Grupo 1 - Arma Curta Uso Permitido', value: '1'}, {label: 'Grupo 2 - Arma Longa Raiada Uso Permitido', value: '2'}, {label: 'Grupo 3 - Arma Longa Lisa Uso Permitido', value: '3'}, {label: 'Grupo 4 - Arma Curta Uso Restrito', value: '4'}, {label: 'Grupo 5 - Arma Longa Raiada Uso Restrito', value: '5'}, {label: 'Grupo 6 - Arma Longa Lisa Uso Restrito', value: '6'}].find(option => option.value == weaponGroup)}
                                            name='weapon_group'
                                            id='weapon_group'
                                            onChange={(data: any) => {
                                                setWeaponGroup(data.value)
                                            }}
                                            placeholder='Selecione o grupo da arma'
                                        />
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <label htmlFor="description" className="form-label">Modelo da Arma<i
                                        className="text-danger"> *</i></label>
                                    <input type="text" className="form-control" id="description" placeholder="Modelo"
                                           onChange={evento => setDescription(evento.target.value)}
                                           value={description}/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="serial_number" className="form-label">Nº Serie<i
                                        className="text-danger"> *</i></label>
                                    <input type="text" className="form-control" id="serial_number"
                                           placeholder="Nº Serie"
                                           onChange={evento => setSerialNumber(evento.target.value)}
                                           value={serialNumber}/>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="sigma_number" className="form-label">Nº Sigma<i
                                        className="text-danger"> *</i></label>
                                    <input type="text" className="form-control" id="sigma_number" placeholder="Nº Sigma"
                                           onChange={evento => setSigmaNumber(evento.target.value)}
                                           value={sigmaNumber}/>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="weapon_category_id" className="form-label">Espécie<i
                                        className="text-danger"> *</i></label>
                                    <Select
                                        options={arrayWeaponCategory}
                                        name='weapon_category_id'
                                        id='weapon_category_id'
                                        onChange={(data: any) => {
                                            setWeaponCategory(data.value)
                                            calibers(data.value)
                                        }}
                                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                                        value={arrayWeaponCategory.find((option: {
                                            value: any;
                                        }) => option.value == weaponCategory) || ''}
                                        placeholder='Espécie da arma'
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="weapon_size" className="form-label">Tamanho <i
                                        className="text-danger"> *</i></label>
                                    <Select
                                        defaultValue={{label: 'Curta', value: 'C'}}
                                        options={[{label: 'Curta', value: 'C'}, {label: 'Longa', value: 'L'}]}
                                        value={[{label: 'Curta', value: 'C'}, {label: 'Longa', value: 'L'}
                                        ].find(option => option.value == weaponSize)}
                                        name='weapon_size'
                                        id='weapon_size'
                                        onChange={(data: any) => {
                                            setWeaponSize(data.value)
                                        }}
                                        placeholder='Selecione o tamanho da arma'
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="weapon_barrel" className="form-label">Cano <i
                                        className="text-danger"> *</i></label>
                                    <Select
                                        defaultValue={{label: 'Liso', value: 'L'}}
                                        options={[{label: 'Liso', value: 'L'}, {label: 'Raiado', value: 'R'}]}
                                        value={[{label: 'Liso', value: 'L'}, {label: 'Raiado', value: 'R'}
                                        ].find(option => option.value == weaponBarrel)}
                                        name='weapon_barrel'
                                        id='weapon_barrel'
                                        onChange={(data: any) => {
                                            setWeaponBarrel(data.value)
                                        }}
                                        placeholder='Selecione a alma do cano da arma'
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="caliber_id" className="form-label">Calibre<i
                                        className="text-danger"> *</i></label>
                                    <Select
                                        isDisabled={!weaponCategory}
                                        options={arrayCaliber}
                                        name='caliber_id'
                                        id='caliber_id'
                                        onChange={(data: any) => {
                                            setCaliber(data.value)
                                        }}
                                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                                        value={arrayCaliber.find((option: {
                                            value: any;
                                        }) => option.value == caliber) || ''}
                                        placeholder='Calibre da arma'
                                    />
                                </div>

                                <div className="col-md-3">

                                    <label htmlFor="weapon_operation_id" className="form-label">Funcionamento<i
                                        className="text-danger"> *</i></label>
                                    <Select
                                        options={arrayWeaponOperation}
                                        name='weapon_operation_id'
                                        id='weapon_operation_id'
                                        onChange={(data: any) => {
                                            setWeaponOperation(data.value)
                                        }}
                                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                                        value={arrayWeaponOperation.find((option: {
                                            value: any;
                                        }) => option.value == weaponOperation) || ''}
                                        placeholder='Funcionamento da arma'
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="weapon_brand_id" className="form-label">Marca<i
                                        className="text-danger"> *</i></label>
                                    <Async
                                        name="search"
                                        isLoading={false}
                                        cacheOptions
                                        loadOptions={weaponsBrands}
                                        noOptionsMessage={() => "Nenhum registro encontrado"}
                                        placeholder='Selecione a marca'
                                        onChange={(data: any) => {
                                            setWeaponBrand(data);
                                        }}
                                        value={weaponBrand}
                                        theme={(theme) => (SelectTheme(theme))}
                                        defaultOptions
                                    />
                                </div>

                                <div className="col-md-3">

                                    <label htmlFor="weapon_collection_id" className="form-label">Acervo<i
                                        className="text-danger"> *</i></label>
                                    <Select
                                        isMulti
                                        options={arrayWeaponCollection}
                                        name='weapon_collection_id'
                                        id='weapon_collection_id'
                                        onChange={(data: any) => {
                                            setWeaponCollection(data)
                                        }}
                                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                                        value={weaponCollection || ''}
                                        placeholder='Acervo da arma'
                                    />
                                </div>

                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">

                                    <div className="col-md-6">
                                        <label htmlFor="craf_expedition" className="form-label">Data expedição da CRAF<i
                                            className="text-danger"> *</i></label>
                                        <DesktopDatePicker className={"form-control sadate"}
                                                    value={crafExpedition != null ? dayjs(crafExpedition) : null}
                                                    onChange={evento => setcrafExpedition(evento)}/>
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="craf_validity" className="form-label">Data validade CRAF<i
                                            className="text-danger"> *</i></label>
                                        <DesktopDatePicker className={"form-control sadate"}
                                                    value={crafValidity != null ? dayjs(crafValidity) : null}
                                                    onChange={evento => setCrafValidity(evento)}/>
                                    </div>

                                </LocalizationProvider>

                            </div>

                        </div>
                    </div>

                    <h3>Guias da Arma</h3>

                    <div>
                        <div className="col-md-12 my-3">
                            <button type="button" onClick={addGuide} className="btn btn-sm btn-primary"><i
                                className="bi bi-plus-square-fill">&nbsp;</i> Adicionar guias
                            </button>
                        </div>
                    </div>

                    {weaponGuides.map((weaponGuide, index) => (
                        <div className="card" key={index}>
                            <div className="card-body">
                                <div className="row mt-1">
                                    <div className="col-9 ps-0">
                                        <span className="badge rounded-pill bg-primary-subtle border-primary-subtle text-primary-emphasis m-2">{index + 1}</span>
                                        {/*<span className="badge rounded-pill bg-success-subtle border-success-subtle text-success-emphasis">Valida</span>*/}
                                </div>
                                <div className="col-3 text-end">
                                    <button type="button" onClick={() => removeGuide(index)} className="btn btn-sm btn-danger my-2"><i className="bi bi-x-lg"></i></button>
                                </div>
                            </div>

                            <div className="row g-3">
                                <div className="col-md-4">
                                    <label htmlFor="guide_type_id" className="form-label">Tipo da guia <i className="text-danger"> *</i></label>
                                    <Select
                                        options={arrayGuideType}
                                        name='guide_type_id'
                                        id='guide_type_id'
                                        onChange={(data: any) => {
                                            handleWeaponGuideChange(index, 'guide_type_id', data.value)
                                        }}
                                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                                        value={arrayGuideType.find((option: {
                                            value: any;
                                        }) => option.value == weaponGuide.guide_type_id) || ''}
                                        placeholder='Tipo de guia'
                                    />
                                </div>

                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                    <div className="col-md-4">
                                        <label htmlFor="traffic_guide_expedition" className="form-label">Data de expedição da guia <i className="text-danger"> *</i></label>
                                        <DesktopDatePicker className={"form-control sadate"}
                                                    value={weaponGuide?.guide_expedition != null ? dayjs(weaponGuide?.guide_expedition): null}
                                                    onChange={evento => handleWeaponGuideChange(index, 'guide_expedition', evento)}/>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="traffic_guide_validity" className="form-label">Data de validade da guia <i className="text-danger"> *</i></label>
                                        <DesktopDatePicker className={"form-control sadate"}
                                                    value={weaponGuide?.guide_validity != null ? dayjs(weaponGuide?.guide_validity): null}
                                                    onChange={evento => handleWeaponGuideChange(index, 'guide_validity',  evento)}/>
                                    </div>
                                </LocalizationProvider>

                                <div className="col-md-12">
                                    <label htmlFor="observation" className="form-label">Destino da guia</label>
                                    <textarea className={"form-control"} name="observation" rows={2}
                                              onChange={evento => handleWeaponGuideChange(index, 'observation', evento.target.value)}
                                              value={weaponGuide.observation}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}


                    <div>
                        <button type="submit" className="btn btn-success me-2">Salvar</button>
                        <Link to={isAthlete ? "/weapons" : '/habitualities/create'} className="btn btn-danger">Voltar</Link>
                    </div>
                </form>

            </div>
        </>
    );
}

