import React, {useContext, useEffect, useState} from "react";
import PrintingComponent from "../../../components/printingComponent";
import {MainContext} from "../../../context/MainContext";
import PresenceReport from "./PresenceReport";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Swal from "sweetalert2";

export default function PresenceReportComponent({ athleteId }:any) {
    const {setPageTitle} = useContext(MainContext)

    const [print, setPrint] = useState<any>({})
    const [startDate, setStartDate] = useState(dayjs().add(-1, 'month') as any);
    const [endDate, setEndDate] = useState(dayjs() as any);

    const childToParent = (childdata:any) => {
        setPrint({imprimir:false})
    }

    const validateFilters = () => {
        if (!startDate) {
            throw "Data inicial deve ser informada";
        }

        if (!endDate) {
            throw "Data final deve ser informada";
        }
    }

    const validatePrint = () => {
        try {
            validateFilters()
            setPrint({ imprimir: true })
        }catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }
    }

    useEffect(() => {
        setPageTitle("Relatório de Presenças")
    }, [])

    return (
        <>
            <div>
                <div className="card">
                    <div className="card-body">
                        {/*<h5 className="card-title">Filtros</h5>*/}

                        <div className="row g-3 mt-3">
                            <div className="col-lg-12">
                                <div className="row g-3">
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                        <div className="col-md-3">
                                            <label htmlFor="startDate" className="form-label">Data inicial <i
                                                className="text-danger"> *</i></label>
                                            <DatePicker className={"form-control sadate"}
                                                        value={startDate}
                                                        onChange={evento => setStartDate(evento)}/>
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="endDate" className="form-label">Data final <i
                                                className="text-danger"> *</i></label>
                                            <DatePicker className={"form-control sadate"}
                                                        value={endDate}
                                                        onChange={evento => setEndDate(evento)}/>
                                        </div>
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>

                        <button type={"button"} className="btn btn-primary mt-3"
                                onClick={() => validatePrint()}>
                            <i className="bi bi-printer-fill"></i> Imprimir
                        </button>
                    </div>
                </div>
            </div>
            {print.imprimir &&
                <PrintingComponent componentToPrint={<PresenceReport filtro={{period: {startDate: dayjs(startDate).format('YYYY-MM-DD')  , endDate: dayjs(endDate).format('YYYY-MM-DD') }}}/>}
                                   textButon={""} childToParent={childToParent}/>
            }
        </>
    )
}
