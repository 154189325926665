import React, {useContext} from "react";
import InputMask from 'react-input-mask';
import Select from "react-select";
import {AthleteContext} from "../../context/Athele/AthleteContext";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AuthContext} from "../../context/AuthContext";

export default function AtForm() {
    const {fullName,setFullName,
        cpf, setCpf,
        rg, setRg,
        genre, setGenre,
        phone, setPhone,
        birthDate, setBirthDate} = useContext(AthleteContext)

    const { authUser, authenticated } = useContext(AuthContext)

    return (
        <>
                <div className="col-md-12">
                    <label htmlFor="full_name" className="form-label">Nome <i className="text-danger"> *</i></label>
                    <input type="text" className="form-control" id="full_name" placeholder="Seu nome"
                           onChange={evento => setFullName(evento.target.value)} value={fullName}/>
                </div>

                <div className="col-md-4">
                    <label htmlFor="cpf" className="form-label">Cpf <i className="text-danger"> *</i></label>
                    <InputMask type="text" className="form-control" id="cpf" placeholder="Seu CPF"
                               mask="999.999.999-99" onChange={evento => setCpf(evento.target.value)} value={cpf}/>
                </div>

                <div className="col-md-4">
                    <label htmlFor="rg" className="form-label">RG {(authUser?.role === 'athlete' || !authenticated) &&<i className="text-danger"> *</i>}</label>
                    <input type="text" className="form-control" id="rg" placeholder="Seu RG"
                           onChange={evento => setRg(evento.target.value)} value={rg}/>
                </div>

                <div className="col-md-4">
                    <label htmlFor="genre" className="form-label">Gênero <i className="text-danger"> *</i></label>
                    <Select
                        defaultValue={{label: 'Masculino', value: 'M'}}
                        options={[{label: 'Masculino', value: 'M'}, {label: 'Feminino', value: 'F'}]}
                        value={[{label: 'Masculino', value: 'M'}, {
                            label: 'Feminino',
                            value: 'F'
                        }].find(option => option.value == genre)}
                        name='genre'
                        id='genre'
                        onChange={(data: any) => {
                            setGenre(data.value)
                        }}
                    />
                </div>

                <div className="col-md-6">
                    <label htmlFor="phone" className="form-label">Celular {(authUser?.role === 'athlete' || !authenticated) &&<i className="text-danger"> *</i>}</label>
                    <InputMask type="text" className="form-control" id="phone" placeholder="Seu número de celular"
                               mask="(99) 99999-9999" onChange={evento => setPhone(evento.target.value)} value={phone}/>
                </div>


            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <div className="col-md-6">
                    <label htmlFor="birthDate" className="form-label">Data nascimento {(authUser?.role === 'athlete' || !authenticated) &&<i className="text-danger"> *</i>}</label>
                    <DesktopDatePicker  className={"form-control sadate"}
                                 value={birthDate}
                                 onChange={evento => setBirthDate(evento)}/>
                </div>
            </LocalizationProvider>

        </>
    );
}

